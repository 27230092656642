<script>
import L from "leaflet";
import {
  LMap,
  //LPolyline,
  LMarker,
  LIcon,
  LTooltip,
  LTileLayer,
} from "vue2-leaflet";
//import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
/**
 * Map component
 */

export default {
  props: ["stations", "loader"],
  mounted() {
    this.stationsPositions = this.stations;
  },
  components: {
    LMap,
    LMarker,
    LIcon,
    LTooltip,
    LTileLayer,
  },
  data() {
    return {
      mapZoom: 6,
      mapCenter: [27.105314, -7.684968],
      stationsPositions: [],

      icon: L.icon({
        iconUrl: require("@/assets/images/iconDevice.svg"),
        iconSize: [41, 41],
        iconAnchor: [16, 37],
      }),

      clusterOptions: {
        showCoverageOnHover: false,
      },

      imageDevice: require("@/assets/images/iconDevice.svg"),

      //imageDeviceGreen: require("@/assets/images/pin_green.svg"),

      url: "http://{s}.google.com/vt/lyrs=m&gl=ma&x={x}&y={y}&z={z}",
      staticAnchor: [16, 10],
      iconAnchor: [20, 40],
      iconSize: [40, 40],
      tooltipAnchor: [0, -30],

      loaderDevice: false,

      lastData: null,

      series: [],
      chartOptions: {
        chart: {
          animations: {
            enabled: false,
          },
          zoom: {
            enabled: true,
          },
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: "straight",
          width: 2,
        },
        colors: ["#F42020"],
        xaxis: {
          type: "datetime",
          axisTicks: {
            show: true,
            borderType: "solid",
            color: "#78909C",
            height: 6,
            offsetX: 0,
            offsetY: 0,
          },
        },
        grid: {
          borderColor: "#78ACD8",
        },
        tooltip: {
          x: {
            show: true,
            format: "dd MMM yyyy hh:mm",
          },
          y: {
            formatter: (value) => {
              return value + " %";
            },
            title: {
              formatter: (seriesName) => seriesName,
            },
          },
        },
        markers: {
          hover: {
            size: 0,
            sizeOffset: 3,
          },
        },
        noData: {
          text: "No Data",
          align: "center",
          verticalAlign: "middle",
          style: {
            fontSize: "20px",
          },
        },
      },

      diskSeries1: [],
      diskSeries2: [],
      diskSeries3: [],
      diskChartOptions1: {
        chart: {
          height: 30,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "50%",
            },
            dataLabels: {
              show: true,
              value: {
                formatter: function (val) {
                  return parseFloat(val * 512 / 100).toFixed(1)  + "Go";
                },
                color: "#111",
                fontSize: "17px",
                show: true,
              },
            },
          },
        },
        labels: ["Utilisé"],
        colors: ["#F72F29"],
      },
      diskChartOptions2: {
        chart: {
          height: 30,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "50%",
            },
            dataLabels: {
              show: true,
              value: {
                formatter: function (val) {
                  return parseFloat(val * 512 / 100).toFixed(1) + "Go";
                },
                color: "#111",
                fontSize: "17px",
                show: true,
              },
            },
          },
        },
        labels: ["Disponible"],
        colors: ["#19EA1F"],
      },
      diskChartOptions3: {
        chart: {
          height: 30,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "50%",
            },
            dataLabels: {
              show: true,
              value: {
                formatter: function (val) {
                  return parseFloat(val).toFixed(1) + "Go";
                },
                color: "#111",
                fontSize: "17px",
                show: true,
              },
            },
          },
        },
        labels: ["Capacité"],
        colors: ["#C8CBC8"],
      },
      powerVolt: null,
      powerCurr: null,
      powerVal: null,
    };
  },
  computed: {
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    },
  },
  methods: {
    passData(data) {
      let d = data.lastData;
      //this.data = data.data;
      delete d.createdAt;
      delete d.updatedAt;
      delete d.id;
      this.lastData = d;
      let dataFormatted = [];
      data.data.forEach((obj) => {
        dataFormatted.push({
          x: obj.createdAt,
          y: obj.data.system.cpu.usage_percent,
        });
      });
      this.series = [
        {
          name: "CPU",
          data: dataFormatted,
        },
      ];
      let capacity = parseFloat(d.data.system.disk_usage[0].used) + parseFloat(d.data.system.disk_usage[0].available);
      this.diskSeries1 = [parseFloat(d.data.system.disk_usage[0].used) * 100 / capacity];
      this.diskSeries2 = [parseFloat(d.data.system.disk_usage[0].available) * 100 / capacity];
      this.diskSeries3 = [capacity];
      this.powerVolt = d.data.power.voltage;
      this.powerCurr = d.data.power.current;
      this.powerVal = d.data.power.power;
      document.getElementById("dataStation").style.visibility = "visible";
    },
    hideData() {
      document.getElementById("dataStation").style.visibility = "hidden";
    },
  },
  watch: {
    stations(newVal) {
      let results = newVal.map((obj) => ({
        ...obj,
        devices: obj.lastData.data.devices,
      }));
      this.stationsPositions = results;
    },
  },
  beforeDestroy() {},
};
</script>

<template>
  <div>
    <div
      v-if="loader == true"
      class="loaderDiv d-flex justify-content-center align-items-center"
    >
      <b-spinner
        label="Loading..."
        variant="primary"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>

    <div style="height: 100%">
      <div
        id="dataStation"
        class="showData mt-2"
        style="
          position: absolute;
          z-index: 999;
          right: 3%;
          top: 3%;
          display: flex;
          flex-direction: column;
          background-color: white;
          width: 500px;
          height: 600px;
          border-radius: 15px 15px 15px 15px;
          visibility: hidden;
        "
      >
        <span
          style="position: absolute; right: 5%; cursor: pointer"
          @click="hideData"
          >x</span
        >
        <pre>
          <!-- {{ lastData }} -->
          <h4 class="ml-3 mb-0">Stockage</h4><div style="display: inline-flex">
            <apexchart
              style="max-width: 160px"
              height="200"
              :series="diskSeries1"
              :options="diskChartOptions1"
            ></apexchart><apexchart
              style="max-width: 160px"
              height="200"
              :series="diskSeries2"
              :options="diskChartOptions2"
            ></apexchart><apexchart
              style="max-width: 160px"
              height="200"
              :series="diskSeries3"
              :options="diskChartOptions3"
            ></apexchart>
          </div>
          <h4 class="ml-3 mb-0">CPU</h4><div>
            <apexchart
              class="apex-charts"
              height="250"
              type="line"
              dir="ltr"
              :series="series"
              :options="chartOptions"
            ></apexchart>
          </div>
          <!-- <h4 class="ml-3 mb-0">Alimentation électrique</h4><div style="display: inline-flex">
            <div
              style="width: 150px"
            ><p style="font-size: 40px">{{powerVolt}} V</p></div><div
              style="width: 150px"
            ><p style="font-size: 40px">{{powerCurr}} A</p></div><div
              style="width: 150px"
            ><p style="font-size: 40px">{{powerVal}} W</p></div>
          </div> -->
        </pre>
      </div>
      <l-map
        style="height: 120vh"
        ref="map"
        :zoom="mapZoom"
        :worldCopyJump="true"
        :min-zoom="5"
        :options="{ zoomControl: false }"
        :center="mapCenter"
        @click="passData(marker)"
      >
        <l-tile-layer :url="url" :subdomains="['mt0', 'mt1', 'mt2', 'mt3']" />
        <l-marker
          :zIndexOffset="100"
          v-for="marker in stationsPositions"
          :key="marker.id"
          :draggable="false"
          :icon="icon"
          :lat-lng="{ lat: marker.lat, lng: marker.lng }"
          @click="passData(marker)"
        >
          <l-icon :icon-anchor="staticAnchor">
            <img :width="25" :src="imageDevice" />
          </l-icon>
          <l-tooltip
            class="rounded text-secondary"
            :options="{
              interactive: 'true',
              direction: 'top',
            }"
          >
            <span style="font-weight: 550"
              >{{ $t("tooltips.name.text") }} :</span
            >
            {{ marker.name }}<br /><br />
            <span
              v-for="(device, index) in marker.devices"
              :key="index"
              style="font-weight: 550"
              >{{ device.name }} :
              <span v-if="device.connected" style="color: green">Connecté</span
              ><span v-if="!device.connected" style="color: red"
                >Non Connecté</span
              ><br
            /></span>
          </l-tooltip>
        </l-marker>
      </l-map>
    </div>
  </div>
</template>
<style scoped>
.loaderDiv {
  position: absolute;
  z-index: 5000;
  width: 100%;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background: rgba(255, 255, 255, 0.4);
}
@media (max-width: 760px) {
  .showData {
    top: 60px;
  }
}
</style>