<script>
import Layout from "../../layouts/main";

import StationsMap from "./stations-map";

import {
  layoutComputed,
  authComputed,
  layoutMethods,
  stationsMethods,
  stationsComputed,
} from "@/state/helpers";
/**
 * Stations component
 */
export default {
  page: {
    title: "Stations-HC-PLUS",
    meta: [{ name: "Stations-HC-PLUS" }],
  },
  components: {
    Layout,
    StationsMap,
  },
  mounted() {
    var root = document.getElementsByTagName("html")[0];
    root.style.overflow = "hidden";
  },
  created() {
    this.retrieveStations();
    this.intervalData = setInterval(()=>{
        this.retrieveStations();
    }, 1000 * 60)
  },
  data() {
    return {
      title: "Stations-HC-PLUS",
      items: [
        {
          text: "Nextrack",
        },
        {
          text: "Stations-HC-PLUS",
          active: true,
        },
      ],
      loaderStatus: false,
      stationsData: [],
      intervalData: null,
    };
  },
  computed: {
    ...layoutComputed,
    ...authComputed,
    ...stationsComputed,
    userRole() {
      return this.loggedIn.user.role;
    },
  },
  methods: {
    ...layoutMethods,
    ...stationsMethods,
    retrieveStations() {
      this.getStationsBy({ limit: 10000 });
    },
  },
  watch: {
    getStations(newVal) {
      this.stationsData = newVal.results;
    },
  },
  async beforeDestroy() {
    await this.retrieveProvinces({ pass: true });
    var root = document.getElementsByTagName("html")[0];
    root.style.overflow = "visible";
    this.changeLeftSidebarType({ leftSidebarType: "light" });
    clearInterval(this.intervalData);
  },
};
</script>

<template>
  <Layout>
    <StationsMap
      v-bind:stations="stationsData"
      :loader="loaderStatus"
    />
  </Layout>
</template>
<style scoped>

div /deep/ .footer {
  display: none;
}
div /deep/ #page-topbar {
  display: none;
}
div /deep/ .vertical-menu {
  display: none;
}

@media (max-width: 760px) {
  .horiMenu {
    display: none;
  }
}

div /deep/ .container-fluid {
  padding: 0px;
}
div /deep/ .page-content {
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}
div /deep/ .main-content {
  margin-left: 0px;
}
</style>